.box-setting-display {
    margin-top: 15px;
    padding-left: 8px;
}

.no-select-compoment div {
    background-color: rgba(233, 233, 233, 0.397);
    padding: 10px;

}

.no-select-compoment div h5 {
    text-align: center;
    color: rgba(121, 109, 109, 0.336);

}

.no-select-compoment {

    height: fit-content;
}

.setting-container {

    border-radius: 5px;
    padding: 2px;

}

.full-width {
    width: 100%;
}

.selected-button {
    background-color: #376fd0 !important;
    color: white !important;
}

.field-container {
    padding-top: 10px;
}

.array-value-add-container {
    padding: 2px;
}


.group-el-container {
    width: 100%;
    background-color: white;
   border-bottom: 1px solid #00000038;
}

.group-el-header {

    background-color: #376fd0;
    width: 100%;
    color: white;
    padding: 14px;
    text-align: center;
    text-transform: uppercase;
    position: relative;
}

.group-el-body {
    width: 100%;
    padding: 8px;
}

.element-value {}

.element-lable {
    margin-left: 8px;
    color: #6c757d;
}

.element-value-database {
    /*border-radius: 5px;*/
    width: 100%;
    /*padding: 4px;*/
    /*background-color: rgb(237, 247, 237);*/
    /*color: rgb(30, 70, 32);;*/
    border-left: 2px solid #dc3545;
    margin-bottom: 6px;
}

.element-value-bitrix {
    /*border-radius: 5px;*/
    width: 100%;
    /*padding: 4px;*/
    /*background-color: rgb(253, 237, 237);*/
    /*color: rgb(95, 33, 32) !important;*/
    /*border: 1px solid rgb(95, 33, 32);*/
    /*font-family: Roboto, Helvetica, Arial, sans-serif;*/
    /*font-weight: 400;*/
    /*font-size: 0.875rem;*/
    /*line-height: 1.43;*/
    border-left: 2px solid #ffc107;
    margin-bottom: 6px;
    background: #fffbe6;

}

.element-value-container {
    padding: 4px;
    width: 100%;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.57;
    font-family: "Public Sans", sans-serif;

}

.full-screen-dialog-body {}

.pading-layout {
    padding: 2px;
}

.list-btn {
    width: 100%;
    padding: 5px;
}

.list-btn Button {
    width: 100%;
}

.edit-button-group {
    position: absolute;
    right: 8px;
    top: 11px;
}

.card-group-container {
    box-shadow: 0px 0px 1px 0px #888888;
}


.header-card {
    width: 100%;
}

.header-title {
    padding-bottom: 9px;
    border-bottom: 1px solid #0000002b;
}

.header-action {
    float: right;
}

.public-form-container {
    height: 100% !important;
}
.dashboard-icon-img{
    border-radius: 50%;
}

.collaps-header-action-btn-hide {
    margin-right: 5px;
    border: 1px solid #b7eb8f;
    color: #389e0d;
    background: #f6ffed;
}
.collaps-header-action-btn-delete{
    color: #d4380d;
    background: #fff2e8;
    border: 1px solid #ffbb96;

}

.tab-action-btn{
    margin-right: 10px;
    margin-bottom: 10px;
}

.scroller-div-dashboar{
 
}

.view-title{
    font-weight: bold;
}
.view-answer{

}

.view-div-row{
    padding: 10px 0px;
}

.view-div{
    padding: 5px 0px;
}

.ant-image-preview-wrap{
z-index: 10000 !important;
}
.data-div-att{
    background-color: #f5f5f5;
}

.reload-button{
    float: right;
    top: 12px;
}

.action-div-table{
width: 64px !important;
}
.action-div-button{
float: right;
}
.custom-component-row{
    padding:0px 12px 0px 0px;
}
.setting-drwer{
    display: none;
}
.ant-form-item{
 margin-bottom: 10px !important;
}
.form-list-show{
    margin: 0px 0px 10px 0px;
    border: 1px solid #0000001c;
    box-shadow: 2px 1px 6px #88888869;
    border-radius: 5px;
    padding: 10px;

}
.form-list-show .field-class{


}
.form-list-show .action-class{

}

.element-value-container {
    display: flex;
}


  
  /* @media (max-width: 768px) {
    .JsonForms-container {
        display: inline-block;
         height: 70vh; 
         overflow:auto;
    }
  } */