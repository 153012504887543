/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
@media (min-width: 992px) {
  .layout-dashboard .ant-layout-footer {
    margin: 0 0 20px 20px;
  }
  .layout-dashboard .ant-layout-sider.sider-primary {
    margin: 20px 0 0 20px;
    padding: 13px 20px;
    height: calc(100vh - 20px);
  }
  .layout-dashboard .ant-layout {
    width: auto;
    flex-shrink: 1;
    margin-left: 250px;
  }
  .layout-dashboard .header-control .sidebar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .layout-dashboard .ant-layout-header {
    margin: 10px 20px;
  }
  .layout-dashboard .header-control {
    margin-top: 0;
  }
  .layout-dashboard .header-control .header-search {
    margin: 0 7px;
  }
  .layout-dashboard .header-control .btn-sign-in span {
    display: inline;
  }
  .profile-nav-bg {
    margin-top: -87.8px;
  }
  .card-profile-head {
    margin: -53px 24px 24px;
  }
}

@media (min-width: 992px) {
  .card-billing-info.ant-card .ant-card-body {
    display: flex;
  }
  .layout-dashboard-rtl {
    overflow: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    margin: 20px 20px 0 0;
    height: calc(100vh - 20px);
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout {
    margin-right: 270px;
    margin-left: 0;
  }
  .layout-dashboard-rtl .ant-layout-footer {
    margin: 0 20px 20px 0;
  }
}

@media (max-width: 768px) {
  body {
    touch-action: pan-x pan-y;
    padding: 0 0 30px 0;
    background-color: #fafafa;
    letter-spacing: -0.3px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 11px;
    line-height: 1.6;
    font-weight: 400;
    height: 100%;
    font-family: 'Kanit';
    /*font-family: Lato,sans-serif;*/
    /* text-transform: capitalize; */


  }
  .ant-btn > span {
    font-size: 11px;
  }

  div{
    font-size:11px;
  }
  .layout-dashboard.layout-dashboard-rtl .header-control {
    justify-content: flex-end;

  }

  .lable-txt{
    font-weight: bold;
  }


  .layout-dashboard .header-control {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-top: 10px;
    padding-bottom: 10px !important;
  }

  .breadcrum-container{
    display: none;
  }

  .ant-layout-header-custom{
    margin: 0px !important;
    border-radius:0px !important;
    padding: 0px !important;
    position: fixed !important;
    width: 100% !important;
    z-index: 1 !important;
  }

  .btn-mobile-res{
    display: none;
  }

  .ant-layout-header-custom header-control{

    flex-direction:row !important;
  }

  .btn-container{
    flex: 1 1 0 !important;
  text-align: center !important;
  }
  .drawer-sidebar .ant-drawer-body {
    padding: 0px;
    width: 100% !important;
  }
  .answer-drawer .ant-drawer-content-wrapper{
    top:44px !important;

  }
  .ant-drawer-mask{
    background: none !important;
  }
  .ant-menu-sub{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .ant-menu-sub.ant-menu-hidden{
    display: none;
  }
  .ant-menu-item-only-child{
    width: 50% !important;
  }

  .ui-item-div{
    display: grid !important;
    background: white;
    margin: 5px;
    padding: 10px;
    border-radius:10px ;
    box-shadow: 2px 2px 5px 0px #88888854;
  }
  main.ant-layout-content.content-ant {
    padding-left: 0px !important;
    padding: 43px 0px 0px 0px;
  }

  .more-option-div .ant-drawer-body{
    padding: 0px !important;
  }

  .ant-layout-sider {
    background: #f5f5f5;
  }

  .ant-menu-submenu-title{
    border-bottom: 1px solid #00000017;

  }

  .layout-dashboard .ant-layout-sider.sider-primary {

  }

  .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item .icon, .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu .icon {

     box-shadow: none !important;
  }
  .ui-item-div .icon{
    color:#eb2f96 !important;
  }
  .ui-item-div .label{
    padding: 0px 0px 0px 8px !important;
  }

  .layout-dashboard .ant-layout-content{
    margin: 0px 0px 0px 0px !important;
  }
  .ant-picker-panel-container .ant-picker-panels {
    display: grid;
    flex-wrap: nowrap;
    direction: ltr;
  }
  .drawer-filter{
    width: 100% !important;
  }

  .table-responsive .ant-table-wrapper{
    display: none ;
  }

  .mobile-table .grid-row{

    margin-bottom: 15px;
    border: 1px solid #00000021;
  }

  .grid-Body{
    padding: 10px 10px 0px 10px;
  }

  .grid-footer{
    padding: 5px 10px 5px 10px;
    display: flex;
    background: #f0f0f0;
  }

  .grid-header{
    padding: 5px 10px 5px 10px;
    display: flex;
    background: #f0f0f0;
  }

  .grid-footer-created-at{
    width: 50%;
    display: flex;
    flex-direction: row-reverse;
  }

  .grid-footer-created-by{
    width: 50%;
    float: left;
  }

  .table-responsive .mobile-table{
    display: block;
  }

  .page-continer {
    font-size: 12px !important;
    background-color: #fff;
    padding: 14px;
    box-shadow: 0px 0px 1px 0px #888888;
  }

  .mobile-div{
    display: flex;
    margin-bottom: 10px;
  }

  .desktop-div{
    display: none;
  }

  .task-responsive-div{
    display: flex;
    align-items: center;
    padding: 10px;
    background: #575757;
    color: white;
    font-size: 16px;
  }

  .data-grid-answer{
    display: none;
  }
  .mobile-data-grid-answer{
    display: block;
  }

  .grid-header-1{
    width: 90%;
  }

  .grid-header-2{
    width: 25%;

  }

  .ant-drawer-body{
    padding: 0px 10px;
  }
  .drawer-sidebar{
    display: block;
  }

  .drawer-filter .ant-drawer-body{
    padding: 44px 10px !important;
  }
  .ant-list-bordered {
    border: none;
    border-radius: 2px;
  }

  .drawer-contain span{
    margin-right: 10px;
  }
.custom-component{
  margin-bottom: -15px;
}

.grid-header-2 div{
  float: right;
  margin-right: 2px;
}

.class-workflow-Drawer .ant-drawer-body {
max-height: 350px !important;

}
.ant-drawer-body .ant-steps-vertical{
  padding: 11px !important;
}

  .jsonFormDynamic .MuiGrid-container {
    width: 100%;
    display: contents !important;
    flex-wrap: wrap;
    box-sizing: border-box;
  }

  .ant-drawer-left > .ant-drawer-content-wrapper {
    top: 46px !important;
    bottom: 0;
    left: 0;
   }

  /* .completed{
   
    color: white;
  width: fit-content;
  } */

 

  .layout-dashboard .ant-layout-sider.sider-primary{
    background: #f1f1f1;
  }
  /*.ant-table-row{*/
  /*  display: flex;*/
  /*  flex-wrap: wrap;*/
  /*}*/
  /*.ant-table-cell{*/
  /*  width: 50%;*/
  /*}*/

  

.dashboard-app{
  padding-top: 5px;
  width: 65px; 
  height: 65px; 
}

.application-container :hover{ 
  background: #d9d9d9;    
}

.dashboard-app-img {
display: flex;
justify-content: center;
}

.dashboard-app-lable{
  display: flex;
  justify-content: center;
  margin-top: 2px;
}

.LinesEllipsis{
  font-weight: bold;
  font-size: 9px !important;
}

.dashboard-app-img img{
  width: 100px;
  height: auto;
}
}

