
.scroll-padding {
    padding-right: 0px !important;
  }
  .scroll-width {
    width: calc(100% + 24px) !important;
  }
.box-setting-display {

    padding-left: 13px !important;
}

.no-select-compoment div {
    background-color: rgba(233, 233, 233, 0.397);
    padding: 10px;

}

.no-select-compoment div h5 {
    text-align: center;
    color: rgba(121, 109, 109, 0.336);

}

.no-select-compoment {

    height: fit-content;
}

.setting-container {

    border-radius: 5px;
    padding: 2px;

}

.full-width {
    width: 100%;
}

.selected-button {
    background-color: #376fd0 !important;
    color: white !important;
}

.field-container {
    padding-top: 10px;
}

.array-value-add-container {
    padding: 2px;
}

.submitButton {
    border: 1px solid #376fd0 !important;
    background: #376fd0 !important;
    color: white !important;
    padding: 2px !important;
}


.foter-json-submit {
    border: 1px solid #376fd0 !important;
    background: #376fd0 !important;
    color: white !important;
    float: right;
}

.foter-json-form {
    width: 100%;
    height: 40px;
}

.full-screen-form-container {
    width: 70% !important;
    margin: auto;
    box-shadow: 0px 0px 20px 3px #888888;

}

.full-screen-form-container-view {
    width: 70% !important;
    margin: auto;
    box-shadow: 0px 0px 20px 3px #888888;
    background-color: #f7f7f7;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #fff;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #fff;
}

.button-full-screen-submit {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 16px;
    border-radius: 4px;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #fff;
    background-color: #2e7d32;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);

}

.button-full-screen-close {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 16px;
    border-radius: 4px;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #fff;
    background-color: #d32f2f;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    margin-right: 5px;

}

@media only screen and (max-width: 600px) {
    .full-screen-form-container {
        width: 100% !important;
        margin: auto;
    }

    .full-screen-form-container-view {
        width: 100% !important;
        margin: auto;
    }
}

.style-list-alert {
    width: 100%;

}

.action-icon {
    font-size: 14px !important;
}

.iconButtonCutsom {
    padding: 4px;
}

.bacl-co {
    background-color: red;
}

.form-type-chip {
    background-color: #d32f2f;
    color: #fff;
    width: fit-content;
    padding: 2px 8px 2px 8px;
    border-radius: 2px;
}





.card-div-answer {
    padding: 5px;
}

.card-div-answer-container {
    border: 1px solid #b3d7ff;
    padding: 5px;
    background-color: #4169e129;
    cursor: pointer;
}


.card-div-date {
    font-size: 12px;
}

.card-div-id {
    font-size: 12px;
}



table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

table td,
table th {
}

table tr:nth-child(even) {
    background-color: #f2f2f2;
}


table th {
    text-align: left;
}


.page-continer {
    font-size: 12px !important;
    background-color: #fff;
    padding: 2rem;
    box-shadow: 0px 0px 1px 0px #888888;
}



.action-menu-button  .ant-btn:empty {
    display: contents !important;
}

.action-menu-button  .ant-btn {
    color: rgb(203 66 73) !important;
    border-color: transparent !important;
    background: transparent !important;
    box-shadow: none !important;
}

.action-menu-button .anticon {
    display: block !important;
    /* justify-content: center; */
}
  


.jGCBVS {
    padding: 16px 0px 0px 0px !important;
}


.table-scrol-mid {
    height: 200px;
    overflow-y: scroll;
}


.MuiDialogContent-root{
    padding-top: 10px !important;
}

.custom-app-bar {
    background-color: rgb(255, 255, 255);
    color: black;
}

.icon-boarder {
    background-color: white;
    border: 1px solid #376fd0;
    border-radius: 1px !important;
}

.main-container {
    background-color: white;
    padding: 10px;
}

.show-div {
    display: block;
}

.closs-div {
    display: none;
}

.advance-button {
    margin-top: 1rem;
    border: 1px solid white;
    background-color: white;
    color: #376fd0;
}

.ant-table-tbody > tr > td, .ant-table-thead > tr > th {
    white-space: pre-line;
}

.page-action-header{
    padding: 0px 0px 5px 0px;
    margin-bottom: 26px;
}

.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail{
    position: absolute;
    top: 4px !important;
    left: 16px !important;
    padding: 30px 0 6px;
}

.ant-layout-sider{
    margin: 0px !important;
    height: calc(100vh) !important; 
   
    max-width: 270px ;
    min-width: 270px ;
    width: 270px ;
    box-shadow: 3px 0px 8px 0px #d9d9d9;
}

.ant-layout-sider-children .brand{
    color:rgb(0, 0, 0);
}
.ant-layout-sider-children .ant-menu-title-content{
    color:rgb(0, 0, 0);
}

.ant-layout-sider-children .ant-menu-title-content .label{
    color:rgb(0, 0, 0) !important;
}
.ant-layout-sider-children .ant-menu-title-content .active .label{
    color:black !important;
}

.action-header-button{
    font-weight: 600 !important;
    font-size: 15px !important;
}

.btn-container-header-tool-box{
    padding: 9px 0px 37px 0px;
    
}

.table-responsive .pagination-container{
width:100%;
display: flex;
    justify-content: end;
    padding: 10px 0px 10px 0px;
}
.delete-icon{color: red !important;}

.MuiStepIcon-root.MuiStepIcon-active {
    color: #cb4249 !important;
}

.float-right-btn{
    display: flex;
    flex-direction: column;
    float: right;
}

.net-total{
    padding: 10px;
    text-align: right;
}


.work-flow-step .pending{
    border: 2px solid gray;
}
.work-flow-step .Accept{
    border: 2px solid green;
}

.work-flow-step .Reject{
    border: 2px solid red;
}




.work-flow-step .current-user{
    border: 2px solid #1890ff !important;
}

.header-value-span{
    padding: 10px;
}
.common-padding{
    padding: 5px 13px 20px 13px;
}

.demo-loadmore-list-item{
    box-shadow: 0px 0px 4px 1px #8c8c8c8f;
    padding: 13px !important;
    margin-bottom: 18px;
}
.status-container-reject{
    background-color: #cb4249;
    width: fit-content;
    padding: 0px 11px;
    font-size: 12px;
    font-weight: 400;
    color: white;
    margin-bottom: 10px;
}

.status-container-pending{
    background-color: #faad14;
    width: fit-content;
    padding: 0px 11px;
    font-size: 12px;
    font-weight: 400;
    color: white;
    margin-bottom: 10px;
}

.status-container-inprogress{
    background-color: #007bff!important;
    width: fit-content;
    padding: 0px 11px;
    font-size: 12px;
    font-weight: 400;
    color: white;
    margin-bottom: 10px;
}

.status-container-completed{
    background-color: #28a745;
    width: fit-content;
    padding: 0px 11px;
    font-size: 12px;
    font-weight: 400;
    color: white;
    margin-bottom: 10px;
}
.example {
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    border-radius: 4px;
  }

  .field-container-index{
    width: 100%;
  }

  .index-action{
    padding: 5px;
  }

  .draft-batch{
    float: right;
    background-color: #ff787563;
    margin-top: 4px;
    padding: 0px 5px;
    color:white;

  }

  .mgt-5{
    margin-top: 5px !important;
  }

  .role-permision-main-header{
    width: 100%;
    color: red;
  }

  .role-permision-main-item{
   
    position: absolute;
    top: 6px;
    right: 3px;
  }

  .form-image-show{
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .group-el-body-stage{
    width: 100%;
    margin-top: 20px;
    margin-left: 30px;
  }


  .action-btn-container{
    background-color: black;
    padding-bottom: 50px;
    
  }

  
  .action-btn-container .left-btn{
    background-color: black;
    float: left;
    width: 30%;
    margin-bottom: 25px;
  }
  .action-btn-container .center-btn{
    background-color: black;
    float: left;
    width: 40%;
    margin-bottom: 25px;
  }
  .action-btn-container .rightr-btn{
    background-color: black;
    float: right;
    width: 30%;
    margin-bottom: 25px;
  }

  .capture-btn{
    color: white;
    margin: 0 45% !important;
    
  }


  .MuiStepLabel-label{
    display: none  !important;
  }

  .page-action-process div{
    float: right  !important;
    padding: 5px;
  }


  .page-action-process button{
    background-color: red;
    color:white;
    border-color: red;
    
  }
  .search-header-input{

  }



 .jsonFormDynamic .MuiInput-underline:before {
    left: 0;
    right: 0;
    bottom: 0;
    content: "\00a0";
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
   border-bottom:none !important;
    pointer-events: none;
}

.jsonFormDynamic .MuiInputBase-input {
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    height: 1.1876em;
    margin: 0;
    display: block;
    padding: 6px 9px 7px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
    border: 1px solid #d9d9d9 !important;
}



.jsonFormDynamic .MuiInputLabel-formControl {
    top: 14px;
    font-size: 13px;
    left: 0;
    color: black;
    position: relative !important;
    
    transform: translateZ(10px) !important;
}

.jsonFormDynamic .MuiTypography-h6 {
    display: none;
}

#EnableLable .MuiTypography-h6 {
    display: block;
  }

.jsonFormDynamic .camera-lable {
    margin-bottom: 25px;
}

.jsonFormDynamic .MuiTypography-body1 {
    /* font-size: 14px; */
    /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
    /* font-weight: 400; */
    /* line-height: 1.5; */
    /* letter-spacing: 0.00938em; */
 
    line-height: 1.5715;
    word-wrap: break-word;
    font-size: 13px;
}


.jsonFormDynamic .MuiButton-root {
    color: rgba(0, 0, 0, 0.87);
    padding: 4px;
    font-size: 11px !important;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: inherit !important;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase ;
}

.jsonFormDynamic .MuiButton-containedPrimary {
    color: #fff ;
    background-color: #cb4249 ;
}
.jsonFormDynamic .MuiButton-containedSecondary {
    color: #cb4249 ;
    background-color: white ;
}




.jsonFormDynamic .MuiInputBase-inputMultiline {
    height: 28px !important;
    resize: none;
    padding: 0;
}


.jsonFormDynamic .MuiStep-horizontal {
    display: none;
}

.jsonFormDynamic .MuiStepper-root {
    padding: 1px;
}

.jsonFormDynamic-fixed .MuiStepper-root {
    margin: 10px 0 35px 0;
}

@media (max-width: 768px) {
    .jsonFormDynamic-fixed .MuiStepper-root {
        padding: 1px;
        margin: 0px;
        margin-top: 35px;
    }

    .json-form-footer{
        /* padding: 10px; */
        /* background: red; */
        margin: 0px !important;
        text-align: unset !important;

    }

    .json-form-container{
        height: 60vh;
        overflow: scroll;
    }

    .jsonFormDynamic .MuiButton-containedPrimary{
        width: 48%;
        height: 40px;
        color: white;
        background: #FF5F61 !important;
    }

    .jsonFormDynamic .MuiButton-containedSecondary{
        width: 48%;
        margin-right: 0px;
        height: 40px;
        color: white;
        background: #222222 !important;
    }

    .jsonFormDynamic .MuiButton-contained.Mui-disabled{
        background-color: rgba(0, 0, 0, 0.12) !important;
    }

    .jsonFormDynamic .MuiButton-containedSecondary:hover{
        color: white;
        background: #222222 !important;
    }

}

.jsonFormDynamic .MuiStepper-horizontal .Mui-disabled{
    display: none;
}




input[type="file"] {
    display: none;
}
.custom-file-upload {
    border: 1px solid #ff4d4f;
    display: inline-block;
    padding: 4px 38px;
    text-align: center;
    cursor: pointer;
    width: 100%;
    color: #ff4d4f;
}

.rdw-suggestion-dropdown{
    position: initial !important;
    display: flex;
    flex-direction: column;
    border: 1px solid #F1F1F1;
    min-width: 100px;
    max-height: 150px;
    overflow: auto;
    background: white;
}
.ant-card-meta-title{
    white-space: pre-line !important
}

.time-line-button{
    width: 100%;
}


.required-field{
    color: red;
}


.photCapture-IMAGE{
 
}

.photCapture-IMAGE span{
  
 }

 .photCapture-IMAGE .MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
   
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}

.css-zw3mfo-MuiModal-root-MuiDialog-root{
    z-index: 1000 !important; 
}
 

.ant-image {
    margin-right: 6px !important;
}
.ant-tag {
    white-space: inherit !important;
}

.ant-alert {
    display: inline-flex !important;
    width: 100% !important;
}

.operation-list{
    display: flex;
}

.operation-list .ant-select{
    margin: 0px 8px !important;
}


.attandance-status{
    margin-top: 20px !important;
    align-items: center;
    background-color: #f9f9f9;
    border: 5px solid #e3e3e3;
    border-radius: 50%;
    display: flex;
    font-size: 14px;
    height: 120px;
    justify-content: center;
    margin: 0 auto;
    width: 120px;
}

.attandence-div{
    background-color: #fff;
    padding: 18px 15px;
    border-radius: 8px;
    box-shadow: 0px 0px 6px 0px #88888859;
}

.attandence-out-of-word{
    background-color: #f62d51 !important;
    color: #f8f9fa!important;
}

.attandence-working{
    background-color: green !important;
    color: #f8f9fa!important;
}


.div-with-image{
    margin-top: 12px;
}

.div-with-image input{
margin-left: 10px;
}

.sping-div{
    margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.scrol-table-div{
    
    overflow: auto;
}

.action-header-col{
    padding: 5px;
}


.hide-div{
    display: none;
}
.show-div{
    display: block;
}

.grid-view-lable{
   margin-top: 15px;
   margin-left: 15px;
   font-size: 14px;
}

.carousel-image{
    
}
.show-obje{
    display: inline;;
    margin-right: 5px;
}

.show-obje-fixed{
    margin-right: -1px;
}

.carousel-image img{
    margin: auto;
}

@media only screen and (min-width: 766px) {
    .antd-input-div{
        padding: 0px 10px 0px 0px;
    }
    
    .antd-input-div-right{
        padding: 0px 0px 0px 10px;
    }
   
  }

  .site-card-border-less-wrapper {
    padding: 5px 5px;
    background: #ececec;
  }


  .custom-component{
    top: 14px;
    font-size: 11px !important;
    left: 0;
    color: black;
    position: relative !important;
  }

 


  .ant-select-multiple{
    position:  relative !important;
    white-space: nowrap;
  }
  

  .additional-infor{
    margin-top: 10px;
  padding: 10px;
  box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 18%);
  }


  .data-grid-height{
    height: 58vh !important;
    overflow: auto !important;
    margin-bottom: 0px !important;
  }

  .ant-modal-body{
    max-height: 80vh;
    overflow: auto;

  }

  .data-rgid-main{
    /* max-height: 400px; */
    overflow: auto;
  }

  .aray-div-me{
    margin-top: 10px;
  }

  .flow-input{
    margin-top: 15px !important;
  }
  

  .header-setting-action{
    width: 100%;
   
  }
  .header-setting-action div{
    float: right;
    margin-bottom: 10px;
  }

  .share-location-div{
    padding: 60px 0px;
  }
  .share-location-div Button{
    width: 100%;
  }
  

  .share-location-page div{
    /* display: flex; */
    margin: auto;
    width: fit-content;
  }

  .photCapture-IMAGE{
    margin: auto;
    width: fit-content;

  }

  .photCapture-IMAGE-device{
    margin: auto;
    width: fit-content;
  }
  

  .share-location-page .share-location-page-welcome{
    /* display: flex; */
font-size: 34pt;
color:#cb4249

  }
  
  .share-location-page .share-location-page-lable{
    /* display: flex; */
font-size: 10pt;
text-align: center;
margin-bottom: 20px;
  }

  .red-font{
    color: #cb4249;
    font-size: 17px;
    text-align: center;
    font-weight: bold;
  }

  .css-1kuq5xv-MuiButtonBase-root-MuiIconButton-root{
    width: 100%;
  }
  .photCapture-IMAGE div{
    display: flex;
    color:white;
    background-color: #ff4d4f;
    padding: 5px 10px;
    border-radius: 5px;
  }



  .photCapture-IMAGE span {
    padding: 2px 14px;
    /* font-size: 12px; */
}

.photCapture-IMAGE svg {
    /* margin-top: 3px; */
    font-size: 17px;
}
  
.data-view{
    padding: 10px 0px;
}

.clocked{
    width: 100%;
    display: flex;
   
}


.clocked .container{
    width: 50%;
   padding: 10px;
}


.clocked .clocked-in  {
    background-color: #cb4249;
    color: white;
    border-radius: 5px;
}

.clocked .clocked-out{
    background-color: white;
    color: black;
    border-radius: 5px;
}

.clocked .header{
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 3px 0px;
    font-weight: bold;
    font-size:15px;
}


.answer .lable , .employee-data .welcome{
    font-weight: bolder;
    margin-right: 3px;
}
.clocked .body{
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0px 0px 7px 0px;
}

.timer{
    font-size: 30px;
    font-weight: 100;
}
.header .icon{
   margin-right: 5px;
}

.shop-data .header{
    font-weight: bold;
    font-size: 13px;
}
.shop-data, .pure-data{
    background: white;
    padding: 2px 8px;
}


.msg-container .lable-div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.lable-div-time{
    color: #cb4249;
}

.lable-div-time-lable{
    font-weight: 700;
}

.lable-div .lable-icon{
    font-size: 35px;
}

.lable-div .title-div{
    font-size: 14px;
    font-weight: bolder;
}
.error-div{
    width: 100%;
}

.clocking-form-copntainer .ant-modal-footer{
    display: flex !important;
    align-items: center  !important;
    justify-content: center  !important;
}

.pure-data .shop-data{
    background-color: white !important;
}

.divider-div-answer{
   
    padding: 9px 8px;
    border-radius: 5px;
    margin-bottom: 10px;
    box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 12%);
}

.timmer{
    box-shadow: 0px 0px 3px 3px #88888833;
    margin: 10px 0px 2px 0px;
    padding: 8px 0px;

}

.timmer-container{
   width: fit-content;
   margin: auto;
   font-size: 14px;
}
.lable-drop{
    font-weight: bold;
}

.timmer-value{
    margin: 0px 5px;
    background-color: red;
    color: white;
    padding: 1px 4px;
    border-radius: 5px;
}

.flex-center-2{
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0px;
}

.flex-center-2 .answer{
    flex: 50%;
    padding: 0px 12px;

}


.flex-center-2 .answer .box{
   
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 15px -3px, rgb(0 0 0 / 5%) 0px 4px 6px -2px;
padding: 12px 2px;
}



.flex-center-2 div{
   
   text-align: center;
}

.flot-div{
    display: inline-flex;
    width: 50%;
    margin: 10px 0px;
}


.flot-div .answer{
    padding: 0px 12px;
    width: 100%;
       
   text-align: center;

}

.normal-padding{
    padding: 0px 12px;
    position: unset !important;
    z-index: 0;
}


.flot-div .answer .box{    
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 15px -3px, rgb(0 0 0 / 5%) 0px 4px 6px -2px;

padding: 12px 2px;
}

.ant-modal{
    width: 80vw !important;
  }

.export-file-list{
    width: 100%;
    margin-top: 5px;
}

.clocked-normal-view{
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
}

.clocked-normal-view-atta{
  width:50%;

}

.clocked-normal-view-left{
    padding:0px 10px 0px 0px;
  
  }
  .clocked-normal-view-right{
    padding:0px 0px 0px 10px;
  
  }

  .add-more-shop-modal{
    width: 80vh !important;
  }
  