.box-setting-display {
    margin-top: 15px;
    padding-left: 8px;
}

.no-select-compoment div {
    background-color: rgba(233, 233, 233, 0.397);
    padding: 10px;

}

.no-select-compoment div h5 {
    text-align: center;
    color: rgba(121, 109, 109, 0.336);

}

.no-select-compoment {

    height: fit-content;
}

.setting-container {

    border-radius: 5px;
    padding: 2px;

}

.full-width {
    width: 100%;
}

.selected-button {
    background-color: #376fd0 !important;
    color: white !important;
}

.field-container {
    padding-top: 10px;
}

.array-value-add-container {
    padding: 2px;
}

.submitButton {
    border: 1px solid #376fd0 !important;
    background: #376fd0 !important;
    color: white !important;
    padding: 2px !important;
}


.foter-json-submit {
    border: 1px solid #376fd0 !important;
    background: #376fd0 !important;
    color: white !important;
    float: right;
}

.foter-json-form {
    width: 100%;
    height: 40px;
}

.full-screen-form-container-public-form {
    text-align: start;
    padding: 28px 50px 23px 50px;
    margin: auto;
    box-shadow: 0px 0px 0px 1px #88888854;
    background-color: #fff;
}

.full-screen-form-container-public-form .action{
    text-align: end;
    margin-top: 12px;
}

.full-screen-form-container-view {
    width: 70% !important;
    margin: auto;
    box-shadow: 0px 0px 20px 3px #888888;
    background-color: #f7f7f7;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #fff;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #fff;
}

.button-full-screen-submit {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 16px;
    border-radius: 4px;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #fff;
    background-color: #2e7d32;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);

}

.button-full-screen-close {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 16px;
    border-radius: 4px;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #fff;
    background-color: #d32f2f;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    margin-right: 5px;

}



.style-list-alert {
    width: 100%;

}

.public-form-container {
    background-color: #eaf0f2;
    height: fit-content !important;
    padding-top: 10px;
}

.form-header-title {
    font-size: 19px;
    font-family: 'docs-Roboto', Helvetica, Arial, sans-serif;
    letter-spacing: 0;
}

.form-header-discription {
    font-size: 11pt;
    line-height: 15pt;
    letter-spacing: 0;
    font-family: Roboto, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .2px;
    line-height: 20px;
    color: #202124;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    line-height: 135%;
    padding: 10px 0px 10px 0px;
}


.form-header-button {
    margin-bottom: 15px;
}

.form-header-button button {
    float: right;
    background-color: #376fd0;
    border: none;
    padding: 5px 10px 5px 10px;
    border-radius: 4px;
    color: white;
}


@media only screen and (max-width: 600px) {
    .full-screen-form-container-public-form {
        width: 100% !important;
        margin: auto;
        padding: 23px;
    }

    .full-screen-form-container-view {
        width: 100% !important;
        margin: auto;
    }
}


.full-screen-form-container-public-form .footer{
    
    text-align: center;
    

}
